import { useEffect, useRef } from "react";
import { useNavigate } from "react-router";

export const useComponentDidMount = (handler) => {
  return useEffect(() => {
    return handler();
  }, []);
};

export const useComponentDidUpdate = (handler, deps) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;

      return;
    }

    return handler();
  }, deps);
};

export const useComponentWillUnmount = (handler) => {
  return useEffect(() => handler, []);
};

export const RequireAuth = ({ children }) => {
  const navigate = useNavigate();
  const user = localStorage.getItem('user')

  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user, navigate]);

  return children;
};

export const RequireAuthAdmin = ({ children }) => {
  const navigate = useNavigate();
  const user = localStorage.getItem('user')

  useEffect(() => {
    if (user) {
      // navigate('/dashboard');
    }
  }, [user, navigate]);

  return children;
}