import { createSlice } from "@reduxjs/toolkit";
import { rolesList,addRole,updateRole, deleteRole,getRoleDetailsFromUserIdMain } from "./api";

const initialState = {
    loading: false,
    rolesListData: [],
    roleAdded:false,
    roleUpdated:false,
    roleCurrentFetchCount:0,
    roleTotalCount:0,
    roleDeleted:false,
    roleDetailsMainData:[],
    statusMessage:false
};



export const roleSlice = createSlice({
    name: "roles",
    initialState,
    reducers: {
        reset: (state) => {
            state.loading = false;
            state.roleAdded= false;
            state.roleUpdated = false;
            state.roleDeleted  = false;
            state.statusMessage = false;
            state.roleTotalCount = false;
            return state;
        },
    },
    extraReducers: {
        [rolesList.pending]: (state) => { state.loading = true; },
        [rolesList.fulfilled]: (state, { payload }) => {
            state.rolesListData = payload.data;
            state.roleCurrentFetchCount = payload.data?.data?.fetchCount;
            state.roleTotalCount =  payload.data?.data?.totalCount;
            state.loading = false;
        },
        [rolesList.rejected]: (state, { payload }) => { state.loading = true;
        },
        [addRole.pending]: (state) => { },
        [addRole.fulfilled]: (state, { payload }) => {
            state.roleAdded = payload.data.status
        },
        [addRole.rejected]: (state, action) => {
            console.log(action,"action")
            if(action?.error?.code==="ERR_BAD_RESPONSE"){
                state.statusMessage = "Role Name Already Exists"
            } 
        },
        [updateRole.pending]: (state) => { },
        [updateRole.fulfilled]: (state, { payload }) => {
            state.roleUpdated = true
        },
        [updateRole.rejected]: (state, { payload }) => {
        },
        [deleteRole.pending]: (state) => { },
        [deleteRole.fulfilled]: (state, { payload }) => {
            state.roleDeleted = payload.data.status
        },
        [deleteRole.rejected]: (state, action) => {
            if(action?.error?.code==="ERR_BAD_REQUEST"){
                state.statusMessage = "Unable to delete"
            } 
        },
        [getRoleDetailsFromUserIdMain.pending]: (state) => { 
        },
        [getRoleDetailsFromUserIdMain.fulfilled]: (state, { payload }) => {
            state.roleDetailsMainData = payload?.data
        },
        [getRoleDetailsFromUserIdMain.rejected]: (state, { payload }) => {
        },
    },
});

export const { reset } = roleSlice.actions;

export default roleSlice.reducer;
