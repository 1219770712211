import axios from "axios";
import { BaseUrl,BaseUrlStrapi,getDecodedToken,BaseUrlApp } from "../constants";
import { createAsyncThunk } from "@reduxjs/toolkit";

const token = localStorage.getItem('token');

export const loginAdmin = createAsyncThunk("/login", async (payload) => await axios.post(`${BaseUrl}/login`, payload));

export const usersList = createAsyncThunk("users/list", async (query) => ( await axios.get(`${BaseUrl}/users${query}`, 
{headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}})));

export const rolesList = createAsyncThunk("/roles", async (query) => ( await axios.get(`${BaseUrl}/roles${query}`, 
{headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}})));

export const teamsList = createAsyncThunk("/teams", async (query) => ( await axios.get(`${BaseUrl}/teams${query}`, 
{headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}})));

export const getRolesList = createAsyncThunk("/getroleslist", async () => ( await axios.get(`${BaseUrl}/roles/names`, 
{headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}})));

export const UserById = createAsyncThunk("/getroleslist", async () => ( await axios.get(`${BaseUrl}/roles/names`, 
{headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}})));

export const addUser = createAsyncThunk("user/add", async (payload) => await axios.post(`${BaseUrl}/users`,payload,{headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}}));

export const updateUser = createAsyncThunk("user/update", async (payload) => await axios.put(`${BaseUrl}/users/${payload.id}`,payload,{headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}}));

export const getUserFromuserid = createAsyncThunk("/get-users-userid", async (id) => ( await axios.get(`${BaseUrl}/users/${id}`, 
{headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}})));

export const getteamFromteamid = createAsyncThunk("/get-teams-teamid", async (id) => ( await axios.get(`${BaseUrl}/teams/${id}`, 
{headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}})));

export const getUserNames = createAsyncThunk("/get-users-names", async (id) => ( await axios.get(`${BaseUrl}/auth/users`, 
{headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}})));

export const addRole = createAsyncThunk("role/add", async (payload) => await axios.post(`${BaseUrl}/roles`,payload,{headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}}));

export const updateRole = createAsyncThunk("role/update", async (payload) => await axios.patch(`${BaseUrl}/roles/${payload.id}`,payload,{headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}}));

export const addTeam = createAsyncThunk("team/add", async (payload) => await axios.post(`${BaseUrl}/teams`,payload,{headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}}));

export const updateTeam = createAsyncThunk("team/update", async (payload) => await axios.put(`${BaseUrl}/teams/${payload.id}`,payload,{headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}}));

export const getSangamsList = createAsyncThunk("/getsangamslist", async () => ( await axios.get(`${BaseUrl}/departments`, 
{headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}})));

export const dashboardCountList = createAsyncThunk("/getCount", async () => ( await axios.get(`${BaseUrl}/count`, 
{headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}})));

export const dashboardUsersCount = createAsyncThunk("/getUsersCount", async () => ( await axios.get(`${BaseUrl}/usersCount`, 
{headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}})));

export const getRoleDetailsFromUserId = createAsyncThunk("/getRoleDetailsFromUserId", async (id) => ( await axios.get(`${BaseUrl}/roles/${id}`, {headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}})));

export const getRoleDetailsFromUserIdMain = createAsyncThunk("/getRoleDetailsFromUserIdmain", async (id) => ( await axios.get(`${BaseUrl}/roles/${id}`, {headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}})));

export const getMenus = createAsyncThunk("/getMenus", async () => ( await axios.get(`${BaseUrl}/menus`, {headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}})));

export const getPermissions = createAsyncThunk("/getPermissions", async () => ( await axios.get(`${BaseUrl}/permissions`, {headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}})));

export const getRoleToPermissions = createAsyncThunk("/getRoleToPermissions", async () => ( await axios.get(`${BaseUrl}/roletopermissions/roles/${ localStorage.getItem('roleId')}`, {headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}})));


export const getRoleToPermissionsFromRoleId = createAsyncThunk("/getRoleToPermissionsFromRoleId", async (id) => ( await axios.get(`${BaseUrl}/roletopermissions/roles/${ id}`, {headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}})));

export const addPermission = createAsyncThunk("permission/add", async (payload) => await axios.post(`${BaseUrl}/roletopermissions/multiple`,payload,{headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}}));

export const deletePermission = createAsyncThunk("permission/delete", async (ids) => await axios.delete(`${BaseUrl}/roletopermissions/multiple?ids=${ids}`,{headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}}));

export const postGoogleUserDetails = createAsyncThunk("postGoogleUserDetails", async (payload) => await axios.post(`${BaseUrl}/google/login`,payload));

export const deleteUser = createAsyncThunk("user/delete", async (payload) => await axios.delete(`${BaseUrl}/users/${payload.id}`,{headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}}));

export const deleteRole = createAsyncThunk("role/delete", async (payload) => await axios.delete(`${BaseUrl}/roles/${payload.id}`,{headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}}));

export const deleteTeam = createAsyncThunk("team/delete", async (payload) => await axios.delete(`${BaseUrl}/teams/reportingManager/${payload.id}`,{headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}}));

export const getForumDeleteComments = createAsyncThunk("/getForumDeleteComments", async (id) => ( await axios.get(`${BaseUrlStrapi}/comments?portalComments=${true}`)));

export const getUsersInfoById = async (id) => {
    const config = {
        headers: { Authorization: `${getDecodedToken()?.userData?.token}`, userid: getDecodedToken()?.myDecodedToken?.userId }
    }
    try {
        const response = await apiService.get(`/authentication/users/${id}`, config);
        return response.data;
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

export const loadProfileImage = createAsyncThunk("/loadprofileimage", async (data) => ( await axios.get(`${BaseUrlApp}/files?name=${data}`, {headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}})));

export const forgotpwdAdmin = createAsyncThunk("/forgt-pwd", async (payload) => await axios.post(`${BaseUrl}/forgotPassword`, payload));

export const newpwdAdmin = createAsyncThunk("/new-pwd", async (payload) => await axios.post(`${BaseUrl}/resetPassword`, payload));

export const getAllUsers = createAsyncThunk("/get-all-users", async (data) => ( await axios.get(`${BaseUrl}/auth/users`, {headers: {"Authorization" : localStorage.getItem('token'),"Userid":  localStorage.getItem('userId')}})));








