import { configureStore } from '@reduxjs/toolkit';
import authReducer from "../reducers/auth";
import userReducer from "../reducers/users";
import sidebarReducer from "../reducers/sidebar";
import roleReducer from "../reducers/roles";
import teamReducer from "../reducers/teams";
import dashboardReducer from "../reducers/dashboard";


export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: userReducer,
    role: roleReducer,
    team: teamReducer,
    sidebar:sidebarReducer,
    dashboard:dashboardReducer
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
});



