import React, { Component, Suspense } from 'react'
import { HashRouter, BrowserRouter,Route, Routes,useNavigate,useLocation } from 'react-router-dom'
import './scss/style.scss'
import { RequireAuth, RequireAuthAdmin } from "./utils";
import Login from "./views/login";
import Forgot from "./views/login/forgot";
import Reset from "./views/login/newpwd";

import { useIdleTimer } from "react-idle-timer";
import LinkedinCallback from "./linkedInCallBack";


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))


const AllRoutes = () => {
  const navigate = useNavigate();
  const history = useLocation();

  const handleOnIdle = (event) => {
    localStorage.clear();
    navigate(`/`);
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 10800000,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  // useEffect(() => {
  //   console.log(`You changed the page to:`);
  // }, [history]);

  return (

    <Suspense fallback={loading}>
      <Routes>      
        <Route exact path="/linkedin" element={<LinkedinCallback /> } />
        <Route path="/" element={<RequireAuthAdmin><Login /></RequireAuthAdmin>} /> 
        <Route path="/forgot-pwd" element={<RequireAuthAdmin><Forgot /></RequireAuthAdmin>} /> 
        <Route path="/reset-password" element={<RequireAuthAdmin><Reset /></RequireAuthAdmin>} /> 

        <Route path="*" element={<RequireAuth><DefaultLayout /></RequireAuth>} />
      </Routes>
    </Suspense>

  );
};


const MainApp = () => (
  <BrowserRouter>
    <AllRoutes />
  </BrowserRouter>
);

export default MainApp;


