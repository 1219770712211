import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sidebarShow: true,
};

export const sidebarSlice = createSlice({
    name: "sidebar",
    initialState,
    reducers: {
        reset: () => initialState,
        setSidebarShow:(state,action)=>{
            state.sidebarShow=action.payload 
            return state;
        },
    },
});

export const { reset,setSidebarShow } = sidebarSlice.actions;

export default sidebarSlice.reducer;
