import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store } from './store/index';
import { SnackbarProvider } from 'notistack';
import { GoogleOAuthProvider } from "@react-oauth/google";




createRoot(document.getElementById('root')).render(
  <SnackbarProvider
  anchorOrigin={{
    vertical: "top",
    horizontal: "right",
  }}
  autoHideDuration={2000}
>
 <GoogleOAuthProvider clientId="918140358734-1iqtb22lal7qv1cj95i2b0olnbte9qme.apps.googleusercontent.com">
  <Provider store={store}>
    <App />
  </Provider>
  </GoogleOAuthProvider>,
  </SnackbarProvider>

)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
