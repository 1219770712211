import React,{ useState, useEffect } from 'react';
import { useComponentDidUpdate } from "../../utils";
import { Grid, TextField } from '@mui/material';
import Logo from '../../assets/images/home-logo.png';
import FormControl from '@mui/material/FormControl';
import {loginAdmin,getRoleDetailsFromUserId,getMenus,getPermissions,getRoleToPermissions,postGoogleUserDetails,newpwdAdmin } from '../../reducers/api'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { reset } from '../../reducers/auth';
import { useForm,Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import './style.scss';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Divider from "@mui/material/Divider";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { SVG } from "./iconSVG";

const validation = Yup.object({
	newpassword: Yup.string().required("Required").matches(/(?=.*[a-z])/, 'Password must contain at least one lowercase letter')
	.matches(/(?=.*[A-Z])/, 'Password must contain at least one uppercase letter')
	.matches(/(?=.*[0-9])/, 'Password must contain at least one number')
	.matches(/(?=.*[!@#%&])/, 'Password must contain at least one special character'),
    confirmpassword: Yup
      .string()
      .oneOf([Yup.ref("newpassword"), null], "Passwords must match")
      .required("Confirm password is required"),
});



export default function Login() {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [width, setWidth] = useState("");
	const [showPassword, setShowPassword] = useState({});
	const [showConfirmPassword, setshowConfirmPassword] = useState({});

	
	const [isPasswordVisible, isSetShowPasswordVisible] = useState({ password: false, confirmPassword: false });
	const [isFocused, setIsFocused] = useState(false);
	const {menuDetails,permissionDetails,roleToPermissionDetails,newpwdSuccess } = useSelector((state) => state.auth);

	const { loginSuccess, statusMessage, loading } = useSelector(state => state.auth);
	const { register, handleSubmit,control, formState: { errors }, } = useForm({
		resolver: yupResolver(
			validation
		),
	});



	 
	useEffect(() => {
		document.title = "FeTNA";  
	  }, []);





	useComponentDidUpdate(() => {
		if (newpwdSuccess) {
			enqueueSnackbar("Password Successfully resetted", {
				variant: 'success',
			});
			dispatch('/')		}
		if (statusMessage) {
			enqueueSnackbar(statusMessage, {
				variant: 'error',
			});
		}
		dispatch(reset());
	}, [newpwdSuccess, statusMessage, dispatch, navigate]);

	const onSubmit = (data) => 
	{
		console.log(data,"data")
		let payload = {
			email: urlParams.get("email"),
			token: urlParams.get("token"),
			newpassword:data?.newpassword,
			confirmpassword:data?.confirmpassword
		  };
		  dispatch(newpwdAdmin(payload))
	}
	


	


	  const handleTogglePassword = () => {
		setShowPassword(!showPassword);
	  };

	  const handleToggleConfirmPassword = () => {
		setshowConfirmPassword(!showConfirmPassword);
	  };

	return (

		<Grid container className="login-container bg-banner-image" columns={{ xs: 4, sm: 8, md: 12 }}>

			<Grid item xs={12} sm={12} md={12} display="flex" justifyContent="center" alignItems="center">
			<Box  sx={{ p: 2 }} className="login-box">

				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="">
						<div>
							<div className="flex-justify-center">
								<img src={Logo} alt="Login Logo" className="logo-center" />
							</div>
							<div className="loginTitle"><h3>FeTNA portal</h3></div>
							<div style={{ marginTop: '20px' }} className="password-bg">
								<div className="" style={{fontWeight:'bold',marginBottom:'6px'}}>New Password</div>
								<FormControl variant="filled">								
									 <TextField
									 id="filled-basic"
									size="small"
									variant="filled"
									sx={{ width: 380 }}								
									{...register("newpassword")}
									placeholder="Enter New Password"
									type={!showPassword ? "text" : "password"}
									InputProps={{
									endAdornment: <InputAdornment position="end">{!showPassword ? <Visibility onClick={handleTogglePassword} sx={{ cursor: "pointer" }} /> : <VisibilityOff onClick={handleTogglePassword} sx={{ cursor: "pointer" }} />}</InputAdornment>,disableUnderline: true,
									}}
								/>
								</FormControl>
								{<div className="error-message-login">{errors.newpassword?.message}</div>}
							</div>
							<div style={{ marginTop: '1.5rem' }} className="password-bg">
								<div className="" style={{fontWeight:'bold',marginBottom:'6px'}}>Confirm Password</div>
								<FormControl variant="filled">
									 <TextField
									 id="filled-basic"
									size="small"
									variant="filled"
									sx={{ width: 380 }}								
									{...register("confirmpassword")}
									placeholder="Enter Confirm Password"
									type={!showConfirmPassword ? "text" : "password"}
									InputProps={{
									endAdornment: <InputAdornment position="end">{!showConfirmPassword ? <Visibility onClick={handleToggleConfirmPassword} sx={{ cursor: "pointer" }} /> : <VisibilityOff onClick={handleToggleConfirmPassword} sx={{ cursor: "pointer" }} />}</InputAdornment>,disableUnderline: true,
									}}
								/>
								</FormControl>
								{<div className="error-message-login">{errors.confirmpassword?.message}</div>}
							</div>
							<button id="loginbtn" type='submit' className="login-btn" disabled={loading} style={{ marginTop: 15 }} >
								{loading ? <CircularProgress style={{color:'white',width:'17px',height:'17px'}}   /> : "Submit"}
							</button>
						
						
						
					
						</div>
					</div>
				</form>
				</Box>
			</Grid>
		</Grid>
	);
}
