import React,{ useState, useEffect } from 'react';
import { useComponentDidUpdate } from "../../utils";
import { Grid, TextField } from '@mui/material';
import Logo from '../../assets/images/home-logo.png';
import FormControl from '@mui/material/FormControl';
import {forgotpwdAdmin,getRoleDetailsFromUserId,getMenus,getPermissions,getRoleToPermissions,postGoogleUserDetails } from '../../reducers/api'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { reset } from '../../reducers/auth';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import './style.scss';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


const validation = Yup.object({
	email: Yup.string().required("Required").email('Enter a valid email'),
});



export default function Forgot() {
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [width, setWidth] = useState("");
	const [showPassword, setShowPassword] = useState({});

	const {menuDetails,permissionDetails,roleToPermissionDetails,} = useSelector((state) => state.auth);

	const { forgotSuccess, statusMessage, loading } = useSelector(state => state.auth);
	const { register, handleSubmit, formState: { errors }, } = useForm({
		resolver: yupResolver(
			validation
		),
	});



	 
	useEffect(() => {
		document.title = "Forgot password";  
	  }, []);

	const handleTogglePassword = () => {
		setShowPassword(!showPassword);
	  };




	useComponentDidUpdate(() => {
		if (forgotSuccess) {
			enqueueSnackbar("Mail send successfully", {
				variant: 'success',
			});
			navigate('/');
		}
		// if (statusMessage) {
		// 	enqueueSnackbar(statusMessage, {
		// 		variant: 'error',
		// 	});
		// }
		// dispatch(reset());
	}, [forgotSuccess, statusMessage, dispatch, navigate]);

	const onSubmit = (data) =>
    {
        data['from']='portal';
        dispatch(forgotpwdAdmin(data))
    } 

	const getGoogleUserDetails = (credentialResponse) => {		
		try {
		  const result =  dispatch(postGoogleUserDetails(credentialResponse))		 
		} catch (error) {
			enqueueSnackbar(error.response?.data?.message, {
				variant: 'error',
			 });
		}
	  };

	  const forgotpwdback=()=>{
		navigate('/')
	  }
	return (

		<Grid container className="login-container bg-banner-image" columns={{ xs: 4, sm: 8, md: 12 }}>

			<Grid item xs={12} sm={12} md={12} display="flex" justifyContent="center" alignItems="center">
			<Box  sx={{ p: 2 }} className="login-box">

				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="">
						<div>
							<div className="flex-justify-center">
								<img src={Logo} alt="Login Logo" className="logo-center" />
							</div>
							<div className="loginTitle"><h3>FeTNA portal</h3></div>
							<div>
								<div className="padding-title">Email</div>
								<TextField
									id="filled-basic"
									variant="filled"
									placeholder="Enter Email"
									size="small"
									sx={{ width: 380 }}
									InputProps={{
										disableUnderline: true,
									}}
									{...register("email")}
								/>
								{<div className="error-message-login">{errors.email?.message}</div>}
							</div>
							
							<button id="loginbtn" type='submit' className="login-btn" disabled={loading} style={{ marginTop: 15 }} >
								{loading ? <CircularProgress style={{color:'white',width:'17px',height:'17px'}}   /> : "Send"}
							</button>
                            <div className='forg-pwd' onClick={()=>forgotpwdback()}>Back</div>

						</div>
					</div>
				</form>
				</Box>
			</Grid>
		</Grid>
	);
}
