import { createSlice } from "@reduxjs/toolkit";
import { loginAdmin,getRoleDetailsFromUserId,getMenus,getPermissions,getRoleToPermissions,getRoleToPermissionsFromRoleId,addPermission,deletePermission,postGoogleUserDetails,forgotpwdAdmin,newpwdAdmin   } from "./api";
import jwt from "jwt-decode";

const initialState = {
    loading: false,
    loginSuccess: false,
    forgotSuccess: false,
    newpwdSuccess:false,
    statusMessage: false,
    roleDetails:[],
    menuDetails:[],
    permissionDetails:[],
    roleToPermissionDetails:[],
    roleToPermissionDetailsFromRoleId:[],    
    addPermissionStatus:false,
    deletePermissionStatus:false 
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: {
        [loginAdmin.pending]: (state) => { 
            state.loading = true
        },
        [loginAdmin.fulfilled]: (state, { payload }) => {
            var decodedHeader = jwt(payload.data.data?.token);            
            localStorage.setItem("user", JSON.stringify(payload.data.data));
            localStorage.setItem("profilePicture", payload.data?.data?.data?.profilePicture);
            localStorage.setItem("userName", payload.data?.data?.data?.userName);
            localStorage.setItem("token", payload.data.data?.token);
            localStorage.setItem("userId", decodedHeader?.userId);
            localStorage.setItem("roleId", decodedHeader?.role);
            if (payload.status === 200) {
                state.loginSuccess = payload.data.status;
            } else {
                state.statusMessage = payload.data.message;
            }
            state.loading = false
        },
        [loginAdmin.rejected]: (state, { payload }) => {
            state.loading = false
            state.loginSuccess = false;
            state.statusMessage = 'login Failed!'
        },
        [postGoogleUserDetails.pending]: (state) => { 
        },
        [postGoogleUserDetails.fulfilled]: (state, { payload }) => {
            var decodedHeader = jwt(payload.data.data?.token);            
            localStorage.setItem("user", JSON.stringify(payload.data.data));
            localStorage.setItem("token", payload.data.data?.token);
            localStorage.setItem("userName", payload.data?.data?.data?.userName);
            localStorage.setItem("profilePicture", payload.data?.data?.data?.profilePicture);
            localStorage.setItem("userId", decodedHeader?.userId);
            localStorage.setItem("roleId", decodedHeader?.role);
            if (payload?.data?.code === 200) {
                state.loginSuccess = payload?.data?.status;
            } else {
                state.statusMessage = payload?.data?.message;
            }
        },
        [postGoogleUserDetails.rejected]: (state,action) => {
            if(action?.error?.code==="ERR_BAD_REQUEST"){
                state.statusMessage = "Please register to the platform"
            }   
            state.loginSuccess = false;            
        },
        [getRoleDetailsFromUserId.pending]: (state) => { 
        },
        [getRoleDetailsFromUserId.fulfilled]: (state, { payload }) => {
            state.roleDetails = payload?.data
        },
        [getRoleDetailsFromUserId.rejected]: (state, { payload }) => {
        },
        [forgotpwdAdmin.pending]: (state) => { state.loading = true
        },
        [forgotpwdAdmin.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.forgotSuccess = payload?.data
        },
        [forgotpwdAdmin.rejected]: (state, { payload }) => {state.loading = false
        },
        [newpwdAdmin.pending]: (state) => { state.loading = true
        },
        [newpwdAdmin.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.newpwdSuccess = payload?.data
        },
        [newpwdAdmin.rejected]: (state,action) => {
            console.log(action?.error,"acterr")
            if(action?.error?.code==="ERR_BAD_REQUEST"){
                state.statusMessage = "Unable to reset password"
            }   
            state.newpwdSuccess = false;            
        },
  
        

        

        [getMenus.pending]: (state) => { 
        },
        [getMenus.fulfilled]: (state, { payload }) => {
            state.menuDetails = payload?.data
            localStorage.setItem("menuData", JSON.stringify(payload?.data?.data));
        },
        [getMenus.rejected]: (state, { payload }) => {
        },
        [getPermissions.pending]: (state) => { 
        },
        [getPermissions.fulfilled]: (state, { payload }) => {
            state.permissionDetails = payload?.data
            localStorage.setItem("permissionData", JSON.stringify(payload?.data?.data));
        },
        [getPermissions.rejected]: (state, { payload }) => {
        },
        [getRoleToPermissions.pending]: (state) => { 
        },
        [getRoleToPermissions.fulfilled]: (state, { payload }) => {
            state.roleToPermissionDetails = payload?.data
            localStorage.setItem("roleToPermissionData", JSON.stringify(payload?.data?.data));
        },
        [getRoleToPermissions.rejected]: (state, { payload }) => {
        },
        [getRoleToPermissionsFromRoleId.pending]: (state) => { 
        },
        [getRoleToPermissionsFromRoleId.fulfilled]: (state, { payload }) => {
            state.roleToPermissionDetailsFromRoleId = payload?.data
        },
        [getRoleToPermissionsFromRoleId.rejected]: (state, { payload }) => {
        },

        [addPermission.pending]: (state) => {  state.loading = true 
        },
        [addPermission.fulfilled]: (state, { payload }) => {
            state.addPermissionStatus = payload?.data?.status
            state.loading = false 
        },
        [addPermission.rejected]: (state, { payload }) => {
            state.loading = false
        },

        [deletePermission.pending]: (state) => { state.loading = true 
        },
        [deletePermission.fulfilled]: (state, { payload }) => {state.loading = false 
            state.deletePermissionStatus =  payload?.data?.status
        },
        [deletePermission.rejected]: (state, { payload }) => {state.loading = false 
        },


    },
});

export const { reset } = authSlice.actions;

export default authSlice.reducer;
