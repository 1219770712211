import { createSlice } from "@reduxjs/toolkit";
import { dashboardCountList,dashboardUsersCount } from "./api";

const initialState = {
    loading: false,
    dashboardCountData: [],
    dashboardUsersCountData:[]
};



export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        reset: (state) => {
            state.loading = false;
            return state;
        },
    },
    extraReducers: {
        [dashboardCountList.pending]: (state) => {  state.loading = true;  },
        [dashboardCountList.fulfilled]: (state, { payload }) => {
            state.dashboardCountData = payload.data;
            state.loading = false;
        },
        [dashboardCountList.rejected]: (state, { payload }) => { state.loading = true;
        },  
        [dashboardUsersCount.pending]: (state) => {  state.loading = true;  },
        [dashboardUsersCount.fulfilled]: (state, { payload }) => {
            state.dashboardUsersCountData = payload.data;
            state.loading = false;
        },
        [dashboardUsersCount.rejected]: (state, { payload }) => { state.loading = true;
        },       
    },
});

export const { reset } = dashboardSlice.actions;

export default dashboardSlice.reducer;
