import { createSlice } from "@reduxjs/toolkit";
import { teamsList,getSangamsList,addTeam,getteamFromteamid,updateTeam,deleteTeam,getForumDeleteComments } from "./api";

const initialState = {
    loading: false,
    teamListData: [],
    sangamListData:[],
    teamAdded:false,
    teamUpdated:false,
    teamCurrentFetchCount:0,
    teamTotalCount:0,
    teamListDataFromteamId:false,
    teamDeleted:false,
    forumDeletedData:[]
};

export const teamSlice = createSlice({
    name: "teams",
    initialState,
    reducers: {
        reset: (state) => {
            state.loading = false;
            state.teamAdded = false;
            state.teamUpdated=false;
            state.teamDeleted=false;
            state.statusMessage = false;
            state.forumDeletedData=false;
            state.teamTotalCount=false;
            return state;
        },
    },
    extraReducers: {
        [teamsList.pending]: (state) => {  state.loading = true;  },
        [teamsList.fulfilled]: (state, { payload }) => {
            state.teamListData = payload.data;
            state.teamCurrentFetchCount = payload.data?.data?.fetchCount;
            state.teamTotalCount =  payload.data?.data?.totalCount;
            state.loading = false;
        },
        [teamsList.rejected]: (state, { payload }) => { state.loading = true;
        },
        [getSangamsList.pending]: (state) => {   },
        [getSangamsList.fulfilled]: (state, { payload }) => {
            state.sangamListData = payload.data;
        },
        [getSangamsList.rejected]: (state, { payload }) => { 
        },
        [addTeam.pending]: (state) => { },
        [addTeam.fulfilled]: (state, { payload }) => {
            state.teamAdded = payload.data.status
        },
        [addTeam.rejected]: (state, action) => {
            if(action?.error?.code==="ERR_BAD_RESPONSE"){
                state.statusMessage = "Team Name Already Exists"
            } 
        },
        [updateTeam.pending]: (state) => { },
        [updateTeam.fulfilled]: (state, { payload }) => {
            state.teamUpdated = payload.data.status
        },
        [updateTeam.rejected]: (state, { payload }) => {
        },
        [getteamFromteamid.pending]: (state) => { },
        [getteamFromteamid.fulfilled]: (state, { payload }) => {
            state.teamListDataFromteamId = payload.data;
        },
        [getteamFromteamid.rejected]: (state, { payload }) => {
        },
        [deleteTeam.pending]: (state) => { },
        [deleteTeam.fulfilled]: (state, { payload }) => {
            state.teamDeleted = payload.data.status
        },
        [deleteTeam.rejected]: (state, action) => {
            if(action?.error?.code==="ERR_BAD_REQUEST"){
                state.statusMessage = "Unable to delete"
            } 
        },
        [getForumDeleteComments.pending]: (state) => { state.loading = true;  },
        [getForumDeleteComments.fulfilled]: (state, { payload }) => {
            state.forumDeletedData = payload.data;
            state.loading = false;
        },
        [getForumDeleteComments.rejected]: (state, { payload }) => { state.loading = true;
        },
    },
});

export const { reset } = teamSlice.actions;

export default teamSlice.reducer;
