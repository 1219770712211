import React,{ useState, useEffect } from 'react';
import { useComponentDidUpdate } from "../../utils";
import { Grid, TextField } from '@mui/material';
import Logo from '../../assets/images/home-logo.png';
import FormControl from '@mui/material/FormControl';
import {loginAdmin,getRoleDetailsFromUserId,getMenus,getPermissions,getRoleToPermissions,postGoogleUserDetails } from '../../reducers/api'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { reset } from '../../reducers/auth';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import './style.scss';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Divider from "@mui/material/Divider";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { SVG } from "./iconSVG";

const validation = Yup.object({
	username: Yup.string().required("Required").email('Enter a valid email'),
	password: Yup.string().required("Required").nullable(),
});



export default function Login() {
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [width, setWidth] = useState("");
	const [showPassword, setShowPassword] = useState({});

	const {menuDetails,permissionDetails,roleToPermissionDetails,} = useSelector((state) => state.auth);

	const { loginSuccess, statusMessage, loading } = useSelector(state => state.auth);
	const { register, handleSubmit, formState: { errors }, } = useForm({
		resolver: yupResolver(
			validation
		),
	});
	const googleLogin = useGoogleLogin({
		onSuccess: (tokenResponse) => getGoogleUserDetails({ token: tokenResponse.access_token }),
	  });

	  useEffect(() => {
		const handleStorageChange = (event) => {
		  if (event.key === "linkedin_login_code") {
			loginLinkedIn(event.newValue);
		  }
		};
	
		window.addEventListener("storage", handleStorageChange);
	
		return () => {
		  window.removeEventListener("storage", handleStorageChange);
		};
	  }, []);

	  const requestProfile = () => {
		var oauthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${`781rj60j1l7hmj`}&scope=openid,email,profile&redirect_uri=${window.location.origin}/linkedin`;
		var width = 450,
		  height = 730,
		  left = window.screen.width / 2 - width / 2,
		  top = window.screen.height / 2 - height / 2;
	
		window.open(oauthUrl, "Linkedin", "menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=" + width + ", height=" + height + ", top=" + top + ", left=" + left);
	  };
	 
	useEffect(() => {
		document.title = "FeTNA";  
	  }, []);

	const handleTogglePassword = () => {
		setShowPassword(!showPassword);
	  };

	  useComponentDidUpdate(() => {
		if(roleToPermissionDetails){			
			const RoleToPermissionData =  roleToPermissionDetails?.data;
			if(RoleToPermissionData?.length==0){
				enqueueSnackbar("Unauthorized", {
					variant: 'error',
				});
			}	
		}
			
	 }, [roleToPermissionDetails]);

	useComponentDidUpdate(() => {
		if(menuDetails && permissionDetails && roleToPermissionDetails){
			const menuData = menuDetails?.data;
			const PermissionData =  permissionDetails?.data;
			const RoleToPermissionData =  roleToPermissionDetails?.data;			
			let dashboardid = menuData?.filter((item) => item.name == 'Dashboard');
			let filterdashboard = RoleToPermissionData?.filter((item) => item.permissionDetails.menuId == dashboardid?.[0]?._id);
			if (filterdashboard?.length == 0) {
				// navigate('/users')
				// enqueueSnackbar("Unauthorized", {
				// 	variant: 'error',
				// });
			} else if (filterdashboard?.length >= 0){
				navigate('/dashboard')
			    // enqueueSnackbar("Login Successful", {
				// 	variant: 'success',
				// });
			}	else{
				navigate('/')
			}
		}
			
	 }, [menuDetails, permissionDetails,roleToPermissionDetails]);

	 useEffect(() => {
		let width = document.getElementById("google-signIn")?.offsetWidth;
		if (width) return setWidth(width);
	  }, [document.getElementById("google-signIn")?.offsetWidth]);


	useComponentDidUpdate(() => {
		if (loginSuccess) {
		
			// navigate('/dashboard');
			dispatch(getRoleDetailsFromUserId( localStorage.getItem('roleId')))
			dispatch(getMenus())
			dispatch(getPermissions())
			dispatch(getRoleToPermissions())
		}
		if (statusMessage) {
			enqueueSnackbar(statusMessage, {
				variant: 'error',
			});
		}
		dispatch(reset());
	}, [loginSuccess, statusMessage, dispatch, navigate]);

	const onSubmit = (data) => dispatch(loginAdmin(data))

	const getGoogleUserDetails = (credentialResponse) => {		
		try {
		  const result =  dispatch(postGoogleUserDetails(credentialResponse))		 
		} catch (error) {
			enqueueSnackbar(error.response?.data?.message, {
				variant: 'error',
			 });
		}
	  };

	  const forgotpwd=()=>{
		navigate('/forgot-pwd')
	  }
	return (

		<Grid container className="login-container bg-banner-image" columns={{ xs: 4, sm: 8, md: 12 }}>

			<Grid item xs={12} sm={12} md={12} display="flex" justifyContent="center" alignItems="center">
			<Box  sx={{ p: 2 }} className="login-box">

				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="">
						<div>
							<div className="flex-justify-center">
								<img src={Logo} alt="Login Logo" className="logo-center" />
							</div>
							<div className="loginTitle"><h3>FeTNA portal</h3></div>
							<div>
								<div className="padding-title">Email</div>
								<TextField
									id="filled-basic"
									variant="filled"
									placeholder="Enter Email"
									size="small"
									sx={{ width: 380 }}
									InputProps={{
										disableUnderline: true,
									}}
									{...register("username")}
								/>
								{<div className="error-message-login">{errors.username?.message}</div>}
							</div>
							<div style={{ marginTop: 5 }} className="password-bg">
								<div className="padding-title">Password</div>
								<FormControl variant="filled">
									{/* <TextField
										id="filled-basic"
										size="small"
										variant="filled"
										type="password"
										sx={{ width: 380 }}
										InputProps={{
											disableUnderline: true,
										}}
										placeholder="Enter Password"
										{...register("password")}
									/> */}
									 <TextField
									 id="filled-basic"
									size="small"
									variant="filled"
									sx={{ width: 380 }}								
									{...register("password")}
									placeholder="Enter Password"
									type={!showPassword ? "text" : "password"}
									InputProps={{
									endAdornment: <InputAdornment position="end">{!showPassword ? <Visibility onClick={handleTogglePassword} sx={{ cursor: "pointer" }} /> : <VisibilityOff onClick={handleTogglePassword} sx={{ cursor: "pointer" }} />}</InputAdornment>,disableUnderline: true,
									}}
								/>
								</FormControl>
								{<div className="error-message-login">{errors.password?.message}</div>}
							</div>
							<button id="loginbtn" type='submit' className="login-btn" disabled={loading} style={{ marginTop: 15 }} >
								{loading ? <CircularProgress style={{color:'white',width:'17px',height:'17px'}}   /> : "Sign In"}
							</button>
							<div className='forg-pwd' onClick={()=>forgotpwd()}>Forgot password?</div>
							{/* <Divider sx={{marginTop:1,marginBottom:1}}>OR</Divider> */}
							<div id="google-signIn" className="google-signIn">
							<div className="email-btn" onClick={googleLogin}>
								<div className="displaySpaceBetween login-btn-text">
								{SVG.google} &nbsp;
								Sign in with Google
								<div style={{ width: "6px" }}></div>
								</div>
							</div>
							<div className="email-btn" onClick={requestProfile}>
								<div className="displaySpaceBetween login-btn-text">
								{SVG.linkedIn} &nbsp;
                                 Sign in with LinkedIn
								</div>
							</div>
							{/* <GoogleLogin
								width={width}
								onSuccess={(credentialResponse) => {
								getGoogleUserDetails(credentialResponse);
								}}
								onClick={googleLogin}
								onError={() => {
								console.log("Login Failed");
								}}
								size="large"
								theme="filled_blue"
								context="signin"
							/> */}
							</div>
						</div>
					</div>
				</form>
				</Box>
			</Grid>
		</Grid>
	);
}
