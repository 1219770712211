import { createSlice } from "@reduxjs/toolkit";
import { usersList,UserById,addUser,updateUser,getRolesList,getUserFromuserid,loadProfileImage,getUserNames,deleteUser,getAllUsers } from "./api";

const initialState = {
    loading: false,
    userListData: [],
    roleListData:[],
    userListDataFromuserId:[],
    userCurrentFetchCount:0,
    userDeleted: false,
    userUpdated: false,
    userAdded: false,
    userTotalCount:0,
    userListNames:[],
    statusMessage:false,
    profileimagedetails:{},
    allUsersData:[]
};



export const userSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        reset: (state) => {
            state.loading = false;
            state.userAdded = false;
            state.userUpdated = false;
            state.statusMessage = false;
            state.userDeleted=false;
            state.userTotalCount = false;
            return state;
        },
    },
    extraReducers: {
        [usersList.pending]: (state) => {  state.loading = true; },
        [usersList.fulfilled]: (state, { payload }) => {
            state.userListData = payload.data;
            state.userCurrentFetchCount = payload.data?.data?.fetchCount;
            state.userTotalCount =  payload.data?.data?.totalCount;
            state.loading = false;
        },
        [usersList.rejected]: (state, { payload }) => {  state.loading = true;
        },
        [getAllUsers.pending]: (state) => {  state.loading = true; },
        [getAllUsers.fulfilled]: (state, { payload }) => {
            state.allUsersData = payload.data;
            state.loading = false;
        },
        [getAllUsers.rejected]: (state, { payload }) => {  state.loading = true;
        },
        [getRolesList.pending]: (state) => {   },
        [getRolesList.fulfilled]: (state, { payload }) => {
            state.roleListData = payload.data;
        },
        [getRolesList.rejected]: (state, { payload }) => { 
        },
        [getUserFromuserid.pending]: (state) => { },
        [getUserFromuserid.fulfilled]: (state, { payload }) => {
            state.userListDataFromuserId = payload.data;
            localStorage.setItem("userInfoFromUserDetails", JSON.stringify(payload.data.data));
        },
        [getUserFromuserid.rejected]: (state,action) => {          
        },
        [loadProfileImage.pending]: (state) => { },
        [loadProfileImage.fulfilled]: (state, { payload }) => {
            state.profileimagedetails = payload.data;
        },
        [loadProfileImage.rejected]: (state,action) => {          
        },
        [addUser.pending]: (state) => { },
        [addUser.fulfilled]: (state, { payload }) => {
            state.userAdded = payload.data.status
        },
        [addUser.rejected]: (state, action) => {
            if(action?.error?.code==="ERR_BAD_REQUEST"){
                state.statusMessage = "Data already exist"
            } 
        },
        [updateUser.pending]: (state) => { },
        [updateUser.fulfilled]: (state, { payload }) => {
            state.userUpdated = true
        },
        [updateUser.rejected]: (state, { payload }) => {
        },
        [getUserNames.pending]: (state) => {   },
        [getUserNames.fulfilled]: (state, { payload }) => {
            state.userListNames = payload.data;
        },
        [getUserNames.rejected]: (state, { payload }) => { 
        },
        [deleteUser.pending]: (state) => { },
        [deleteUser.fulfilled]: (state, { payload }) => {
            state.userDeleted = payload.data.status
        },
        [deleteUser.rejected]: (state, action) => {
            if(action?.error?.code==="ERR_BAD_REQUEST"){
                state.statusMessage = "Data already exist"
            } 
        },
    },
});

export const { reset } = userSlice.actions;

export default userSlice.reducer;
